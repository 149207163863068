let constrain = 100;
let mouseOverContainer = document.querySelector(".mouseovercontainer");
let rotatingDiv = document.querySelector(".centerimage");
let registerButton = document.querySelector(".register");
let body = document.querySelector("body");
let registered = false;


if(getCookie('registered') == 'true'){
  console.log('Welcome back');
  registered = true;
  body.classList.add("is-paradise");
  registerButton.innerHTML = 'abmelden' 
}


// Register
registerButton.onmouseenter = function(e) {
  body.classList.add("wants-paradise")
};
registerButton.onmouseleave = function(e) {
  body.classList.remove("wants-paradise")
};
registerButton.onmousedown = function(e) {
  if(registered){
    setCookie("registered", false, 365);
    registered = false;
    body.classList.remove("is-paradise")
    console.log('set false');
    registerButton.innerHTML = 'Anmelden'
  }
  else{
    setCookie("registered", true, 365);
    registered = true;
    console.log('set true');
    body.classList.add("is-paradise")
    registerButton.innerHTML = 'abmelden';
  }
};



// Head rotations
function transforms(x, y, el) {
  let box = el.getBoundingClientRect();
  let calcX = -(y - box.y - (box.height / 2)) / constrain;
  let calcY = (x - box.x - (box.width / 2)) / constrain;
  
  return "perspective(1500px) "
    + "   rotateX("+ calcX +"deg) "
    + "   rotateY("+ calcY +"deg) ";
};

 function transformElement(el, xyEl) {
  el.style.transform  = transforms.apply(null, xyEl);
}

mouseOverContainer.onmousemove = function(e) {
  let xy = [e.clientX, e.clientY];
  let position = xy.concat([rotatingDiv]);

  window.requestAnimationFrame(function(){
    transformElement(rotatingDiv, position);
  });
};

// cookie handler
function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function checkCookie() {
  let username = getCookie("username");
  if (username != "") {
   alert("Welcome again " + username);
  } else {
    username = prompt("Please enter your name:", "");
    if (username != "" && username != null) {
      setCookie("username", username, 365);
    }
  }
}